/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
/* Componentes*/
import Header from "../headerNA"
import Loader from "../loader"
import Ayuda from '../../pages/ayuda'
import CancelarFlujo from '../cancelar_flujo'
/* Librerías */
import { isIOS, isMobile, withOrientationChange } from 'react-device-detect'
import { useHistory } from "react-router-dom"
/* Funciones */
import { cancelarRequestRenapo, cancelarRequestIne, getDocuments, cancelarRequest } from '../../services/api';
import {
    status, statusError, statusData, statusReintento, evento, flowIncompleted, sendEventClick, generateZip,
    cancelarINE, cancelarRENAPO, validarRedireccionamiento, enviarImagenVideotoken, mapearMensajeError, mapearRespuesta, mapearError
} from '../../services/data'
import { configStyle, obtenerValorConfig } from '../../services/configStyle'

/* Mapeo de mensajes */


let videoTag;

const Index = (props) => {

    /* Props */
    const {
        apikey,
        uuidCliente,
        uuidTransaccion,
        dataOtorgante,
        isLandscape,
        service_call,
        previousStep,
        nextStep,
        cancelStep,
        type,
        intents,
    } = props
    /* Hooks */
    const history = useHistory()
    const [estado, setEstado] = useState('')
    const [pathname, setPathname] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [errorStatus, setErrorStatus] = useState('')
    const [response, setReponse] = useState(null)
    const [intentos, setIntentos] = useState(0)
    const [intentosVideotoken, setIntentosVideotoken] = useState(0)
    const [showHelp, setShowHelp] = useState(false)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [error, setError] = useState(false)
    const [sendingPhoto, setSendingPhoto] = useState(true)
    const [isStart, setIsStart] = useState(true)
    const [showCancel, setShowCancel] = useState(false)
    const [getPhoto, setGetPhoto] = useState(false)
    // const [stable, setStable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorCamera, setErrorCamera] = useState(false)
    const [disableFinishButton, setDisableFinishButton] = useState(true)
    const [errorPermiso, setErrorPermiso] = useState(null)
    const [cancelProcess, setCancelProcess] = useState(false)
    /* Referencias */
    const videoRef = useRef(null)
    const canvasRef = useRef(null)
    const tituloRef = useRef(null)

    useEffect(() => {
        if (type === 'selfie') {
            setPathname(window.location.pathname)
            status("captura_video_token_page", "Captura Video Token")
        }
        videoTag = videoRef.current
        return () => {
            const mediaStream = videoTag.srcObject
            if (mediaStream) mediaStream.getTracks().forEach(track => track.stop())
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
        if (dataOtorgante.length > 0) {
            setIntentos(Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_VIDEO_TOKEN', intents)))
            let bloqueo = localStorage.getItem('bloqueo')
            if (bloqueo && bloqueo === 'true') {
                setDisableFinishButton(false)
                setIntentosVideotoken(Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_VIDEO_TOKEN', intents)))
            } else {
                localStorage.setItem('bloqueo', 'false')
            }
        }
        return () => { }
    }, [dataOtorgante])

    useEffect(() => {
        if (errorPermiso === false && getPhoto) {
            capturarImagen(false)
        } else if (errorPermiso === true && getPhoto) {
            setIsStart(false)
        }
        return () => { }
    }, [errorPermiso, getPhoto])

    useEffect(() => {
        if (intentosVideotoken > 0 && errorStatus) {
            if (type === 'selfie') enviarReintento('Intentos Video Token', intentosVideotoken);
            if (intentosVideotoken === intentos) {
                localStorage.setItem('bloqueo', 'true')
                if (type === 'selfie') obtenerDocumentos()
            } else {
                if (type === 'selfie') enviarReintento('Captura Video Token', errorStatus)
            }
        }
        return () => { };
    }, [intentosVideotoken, errorStatus]);


    const permisoCamara = async () => {
        try {
            const localStream = await window.navigator.mediaDevices.getUserMedia({
                // audio: true,
                video: true
            })

            if (videoRef.current) {
                videoRef.current.srcObject = localStream
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play()
                }
                videoRef.current.addEventListener('canplay', () => {
                    canvasRef.current.setAttribute('width', videoRef.current.videoWidth * 1.5)
                    canvasRef.current.setAttribute('height', videoRef.current.videoHeight * 1.5)
                }, false)
            }
            if (localStream.getVideoTracks().length > 0) {
                setErrorCamera(false)
            } else {
                setErrorCamera(true)
                evento('Captura Video Token - ' + type, 'Step', { status: 'ERROR GET VIDEO TRACKS' }, true);
            }

            setErrorPermiso(false)

        } catch (error) {
            handleErrorGetMediaUser(error)
        }

    }

    const handleErrorGetMediaUser = (error) => {
        if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
            //required track is missing 
            evento('Captura Video Token - ' + type, 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true);
        } else if (error.name === "NotReadableError" || error.name === "TrackStartError") {
            //webcam or mic are already in use 
            evento('Captura Video Token - ' + type, 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true);
        } else if (error.name === "OverconstrainedError" || error.name === "ConstraintNotSatisfiedError") {
            //constraints can not be satisfied by avb. devices 
            evento('Captura Video Token - ' + type, 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true);
        } else if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
            //permission denied in browser 
            evento('Captura Video Token - ' + type, 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true)
            setErrorPermiso(true)
        } else if (error.name === "TypeError" || error.name === "TypeError") {
            //empty constraints object 
            evento('Captura Video Token - ' + type, 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true);
        } else {
            //other errors 
            evento('Captura Video Token - ' + type, 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true);
        }

        setErrorCamera(true)
        setGetPhoto(false)
    }

    const capturarImagen = (reIntento) => {
        // let description = reIntento ? 'REINTENTAR' : 'CONTINUAR'
        sendEventClick('Captura Video Token', 'CONTINUAR', {})
        const bloqueo = localStorage.getItem('bloqueo')
        // if (!reIntento) {
        if (tituloRef.current) {
            tituloRef.current.className = 'animate__animated animate__slideInDown'
            tituloRef.current.innerHTML = '<p>Ubica tu rostro <b>en la guía</b>, mantente estable y <b>captura</b> tu rostro</p>'
            setTimeout(() => {
                //setStable(true)
                tituloRef.current.classList.remove('animate__slideInDown')
            }, 1000)
        }
        /* } else {
            tituloRef.current.classList.remove('animate__slideInDown')
            setStable(true)
        } */
        // setError(false)
        setErrorMessage('')
        if (bloqueo === 'false' || bloqueo === null) {
            setIsStart(false)
            setSendingPhoto(false)
        } else {
            setIsStart(false)
        }
    }

    const obtenerImagen = () => {
        setSendingPhoto(true)
        setTimeout(() => {
            //console.log('CANVAS')
            let contextCanvas = canvasRef.current.getContext('2d')
            contextCanvas.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth * 1.5, videoRef.current.videoHeight * 1.5)
            canvasRef.current.toBlob((blob) => {
                enviarImagen(blob)
                // descargarImagen(blob)
            }, 'image/png', 0.9)
        }, 1350)
    }

    const enviarImagen = async (blob) => {
        if (blob) {
            tituloRef.current.className = 'animate__animated animate__slideInDown'
            tituloRef.current.innerHTML = '<p class="animate__animated animate__slideInDown">Procesando, <b>gracias</b> por tu paciencia.</b></p>'
            // setStable(false)
            setLoading(true)
            videoRef.current.classList.add("blur_video")
            setTimeout(() => {
                tituloRef.current.classList.remove('animate__slideInDown')
            }, 1000)
            try {
                const response = await enviarImagenVideotoken(blob, isIOS, service_call)
                const { status, data } = response
                if (status === 200) {
                    evento('Captura videtoken - ' + type, 'Succes', mapearRespuesta(status, data), true)
                    if (type === 'selfie') sendData(data.payload.match)
                    setLocalStorageResultFace(data.payload)
                    if (type === 'pdf') localStorage.setItem('enlace', data.payload.documentLink)
                    setTimeout(() => {
                        history.push(nextStep)
                    }, 300);
                }
            } catch (error) {
                let errorMapeado = mapearError(error)
                evento('Captura videtoken - ' + type, errorMapeado.tipoError, errorMapeado.objetoError, false)
                if (errorMapeado.objetoError.tipo === 'RESPONSE') {
                    setReponse(errorMapeado)
                    let mensaje = ''
                    if (error.response.data) {
                        mensaje = error.response.data.message_client
                    }
                    let errorMessage = mapearMensajeError(mensaje)
                    if (error.response.status === 500 || error.response.status === 400) {
                        if (mensaje === 'No se encontro rostro' || mensaje === 'Se detecto cubrebocas' || mensaje === 'Condiciones de luz no adecuadas' || mensaje === 'Imagen borrosa' || mensaje === 'Se detectaron multiples caras' || mensaje === 'Falta de luz' || mensaje === 'No se pudo realizar la comparacion de rostro' || mensaje === 'No logramos detectar un rostro') {
                            setErrorMessage(errorMessage)
                            setErrorStatus(errorMessage)
                            setError(true)
                            setLoading(false)
                        } else {
                            setErrorMessage(errorMessage)
                            setErrorStatus(errorMessage)
                            setError(true)
                            setLoading(false)
                            setIntentosVideotoken(intentosVideotoken + 1)
                        }
                    } else if (error.response.status === 404) {
                        setErrorMessage(errorMessage)
                        setErrorStatus(errorMessage)
                        setError(true)
                        setLoading(false)
                        setIntentosVideotoken(intentosVideotoken + 1)
                    } else {
                        setErrorMessage('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        setError(true)
                        setLoading(false)
                    }
                } else {
                    if (error.toString().includes('Network')) {
                        setErrorMessage('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        setError(true)
                        setLoading(false)
                    } else if (error.toString().includes('cancel')) {
                        setErrorMessage('Se canceló el flujo')
                        setErrorStatus('Se canceló el flujo')
                        setLoading(false)
                    } else {
                        setErrorMessage('El servicio no se encuentra disponible, lo solucionaremos en breve')
                        setErrorStatus('Error en la captura')
                        setError(true)
                        setLoading(false)
                    }
                }
                if (videoRef.current) {
                    videoRef.current.classList.remove("blur_video")
                }
            }
        }
    }

    const reintentarCapturarImagen = () => {
        sendEventClick('Captura Video Token', 'REINTENTAR', {})
        if (tituloRef.current) {
            tituloRef.current.className = 'animate__animated animate__slideInDown'
            tituloRef.current.innerHTML = '<p>Ubica tu rostro <b>en la guía</b>, mantente estable y <b>captura</b> tu rostro</p>'
            setTimeout(() => {
                tituloRef.current.classList.remove('animate__slideInDown')
            }, 1000)
        }
        setError(false)
        setSendingPhoto(false)
    }

    const sendData = (match) => {
        statusData("captura_video_token", { coincidencia: match, speech: true })
    }

    const setLocalStorageResultFace = (result) => {
        let face = {}
        if (result.match) {
            face = {
                match: result.match,
                liveness: result.liveness
            }
        } else {
            face = {
                match: false,
                gender: "Indeterminado",
                age: 0,
                expressions: {
                    happy: 0.0
                },
                liveness: "No superada"
            };
        }
        localStorage.setItem("face", JSON.stringify(face));
    }

    const enviarReintento = (step, description) => {
        statusReintento(step, description, 'RETRY');
    }

    const obtenerDocumentos = async () => {
        try {
            const response = await getDocuments()
            const { status, data, data: { payload } } = response
            evento('Obtener documentos - ' + type, 'Succes', mapearRespuesta(status, data), true)
            if (status === 200) {
                if (payload.links.length > 0) {
                    let links = JSON.stringify(payload.links);
                    localStorage.setItem('links', links);
                }
            }
            setTimeout(() => {
                finalizarFlujo()
            }, 300);
        } catch (error) {
            let errorMapeado = mapearError(error)
            evento('Obtener documentos - ' + type, errorMapeado.tipoError, errorMapeado.objetoError, false)
            setTimeout(() => {
                finalizarFlujo()
            }, 300);
        }
    }

    const finalizarFlujo = async (response) => {
        setLoading(true)
        await generateZip('SEMAFORO', 'red', isIOS);
        localStorage.setItem('bloqueo', 'true');
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem('time', acceptDta.getTime())
        let res = convertdataJSON();
        let event_t = await JSON.stringify(res);
        var json_final = {};
        let status = "FAIL"
        json_final = {
            "status": status,
            "step": "finished",
            "description": event_t,
            'response': response,
            "finished": true
        }
        const event = JSON.stringify(json_final)
        await flowIncompleted({ 'step': 'captura_video_token', 'event': event }, 'red')
        setLoading(false)
        setDisableFinishButton(false)
    }

    const convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem('uuidUser') || '';
        jsonObj.uuidTrx = localStorage.getItem('uuidTrx') || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem('ocrFront')) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem('ocrBack')) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem('data_user')) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    const cancelarFLujo = async () => {
        if (type === 'selfie') {
            cancelarINE();
            cancelarRENAPO();
            cancelarRequest();
            cancelarRequestRenapo()
            cancelarRequestIne()
            sendEventClick('Captura Video Token', 'SÍ', { status: 'CANCELADO' })
            localStorage.setItem('flag', 'cancelado')
            setLoading(true)
            await generateZip('TRUNCOS', 'trunco', isIOS)
            await statusError("captura_video_token", 'Cancelado', "cancelado")
            if (!window.opener) {
                if (validarRedireccionamiento(dataOtorgante)) {
                    setTimeout(() => {
                        history.push(previousStep)
                        setLoading(false)
                    }, 1000);
                } else {
                    setShowCancelScreen(true)
                    setCancelProcess(false)
                    setEstado('cancelado')
                }
            }
        } else {
            setTimeout(() => {
                history.push(previousStep)
            }, 300);
        }
    }

    // const descargarImagen = (blob) => {
    //     let url = URL.createObjectURL(blob)
    //     let a = document.createElement('a')
    //     document.body.appendChild(a)
    //     a.style = 'display: none'
    //     a.href = url
    //     a.download = 'photo.png'
    //     a.click()
    // }

    const redirect = () => {
        const mediaStream = videoTag.srcObject
        if (mediaStream) mediaStream.getTracks().forEach(track => track.stop())
        if (type === 'selfie') {
            if (validarRedireccionamiento(dataOtorgante)) {
                history.push(previousStep)
            } else {
                setShowCancelScreen(true)
                setEstado('fallido')
            }
        } else {
            history.push(previousStep)
        }
    }

    const cancelarFlujoIntento = () => {
        sendEventClick('Captura Video Token', 'CANCELAR')
        if (type === 'selfie') {
            history.push(`/cancelar_flujo${cancelStep}`)
        } else {
            setShowCancel()
        }
    }

    return (
        <>
            {(error && (intentosVideotoken < Number(obtenerValorConfig(dataOtorgante, "INTENTOS_VIDEO_TOKEN", intents)))) &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className={['modal-dialog', isMobile ? 'modal-center' : 'modal-center-desktop'].join(' ')} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><b>Lo sentimos</b></h5>
                            </div>
                            <div className="modal-body">
                                <p>{errorMessage}</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" data-dismiss="modal" onClick={cancelarFlujoIntento}>Cancelar</button>
                                <button type="button" onClick={reintentarCapturarImagen} className="btn btn-raised btn-primary main_bg_color">Reintentar</button>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className="module_container overflow_hddn" style={{ position: 'fixed' }}>
                <div className="camera_capture_frame speech_mod">
                    <video src="" id="video_wrt_environment" playsInline muted ref={videoRef}></video>
                    <canvas id="canvas_wrt_environment" ref={canvasRef} hidden></canvas>
                </div>
                <div className="module_gradident_overlay"></div>
                <img className="id_mask animate__animated animate__fadeIn animate__delay-2s" src={'/images/face_id_on_grdnt.svg'} alt="" />
                <div className="recorder_container">
                    <div className="module_buttons animate__animated" style={{ position: 'fixed' }}>
                        <button onClick={obtenerImagen} className={`btn btn-raised btn-primary forcewidth100 ${(sendingPhoto) ? 'gray_bg_color' : 'main_bg_color'}  ${(isMobile) ? '' : 'desk_id_capture_btn'}`} disabled={(sendingPhoto) ? true : false}>
                            {/* {(sendingPhoto) ? 'PROCESANDO...' : 'CAPTURAR ROSTRO'}</button> */}
                            CAPTURAR ROSTRO</button>
                    </div>
                </div>
            </div>
            {(isStart && !showHelp) && <>
                <div className="hollo_gif_bx show_id_hold container-flex" style={{ backgroundImage: 'linear-gradient(180deg, var(--color-secondary) 0%, var(--color-primary) 100%)', overflowY: 'scroll' }}>
                    {(showCancel) &&
                        <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Cancelar proceso</h5>
                                        <button onClick={() => setShowCancel(false)} className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={e => { setShowCancel(false); sendEventClick('Captura Video Token', 'NO', {}); }} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                        <button type="button" onClick={cancelarFLujo} className="btn btn-raised btn-primary main_bg_color">SÍ</button>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    <Header show={() => setShowHelp(true)} />

                    <div className="main_text_container text-left custom_padding">
                        <h1 className="animate__animated text-left">Confirmación de identidad</h1>
                        <p>Mantén tu <b>rostro visible, sin más personas</b> en la toma, <b>no</b> utilices <b>gorra</b> y <b>evita</b> colocarte <b>frente</b> a <b>fuentes</b> de luz.<br /></p>
                        <br></br>
                    </div>
                    <div className='container_selfie_instructions container-flex'>
                        <div className='row'>
                            <div className={`${isMobile ? 'col-12  div-text-center' : 'col-6 div-text-right'}`}>
                                <img className={`${isMobile ? 'img_selfie_instructions' : 'img_selfie_instructions_desktop'}`} src={`images/componente_selfie/${apikey === 'gAxydegFi6Wfd7X1QRcvtA9ztXokyQXi' ? 'selfie_error_dy1@2x.png' : 'selfie_error_g1@2x.png'}`} alt='' />
                            </div>
                            <div className={`${isMobile ? 'col-12  div-text-center' : 'col-6 div-text-left'}`}>
                                <img className={`${isMobile ? 'img_selfie_instructions' : 'img_selfie_instructions_desktop'}`} src={`images/componente_selfie/${apikey === 'gAxydegFi6Wfd7X1QRcvtA9ztXokyQXi' ? 'selfie_error_dy2@2x.png' : 'selfie_error_g2@2x.png'}`} alt='' />
                            </div>
                        </div>
                    </div>

                    <div className="action_buttons animate__animated animate__fadeIn animate__delay-1s custom_padding">
                        <button type="button" onClick={() => { setGetPhoto(true); permisoCamara() }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" disabled={getPhoto ? true : false}>CONTINUAR</button>
                        <button onClick={e => { setShowCancel(true); sendEventClick('Captura Video Token', 'CANCELAR', {}); }} className="btn btn-primary forcewidth100 main_color">
                            CANCELAR</button>
                    </div>

                </div>
            </>}

            <div id="cuadro" className="animate__animated animate__slideInDown delay-3s">
                <div id="head_shop" className="txt_videotoken" ref={tituloRef} >
                </div>
            </div>
            {/* {stable &&
                <div id="div-stable" className="animate__animated animate__fadeIn">
                    <div className="sprite_stay_b animado " style={{ top: "25%" }}></div>
                </div>} */}
            {intentosVideotoken >= Number(obtenerValorConfig(dataOtorgante, "INTENTOS_VIDEO_TOKEN", intents)) &&
                (<div className="err_finale animate__animated animate__slideInUp">
                    <div className="center_checks">
                        <h5>Proceso no exitoso</h5>
                        <p>Has superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                        <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(uuidTransaccion).split("-")[0]}</b> </p>
                        <br />
                        {
                            (pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") &&
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <p><b>Llámanos al:</b></p>
                                        <p><a className="help_phone" href={"tel:" + obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                    </div>
                                    <div className="col">
                                        <p><b>Escríbenos a:</b></p>
                                        <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                    </div>
                                </div>
                            </div>
                        }
                        {(!window.opener) &&
                            <div className="action_buttons noscroll_screen">
                                <button className='btn btn-raised btn-primary forcewidth100 main_bg_color' disabled={disableFinishButton} onClick={redirect}>ENTENDIDO</button>
                            </div>
                        }
                    </div>
                </div>)}
            {loading && <Loader />}
            {/*
                (isLandscape) && (
                    <div className="rotate_device">
                        <div className="center_info_bx">
                            <img src="images/rotate_device.svg" height="100" alt="" />
                            <p>Por favor usa tu dispositivo en vertical<br />
                                <small>Gira tu dispositivo para continuar</small>
                            </p>
                        </div>
                    </div>)
                */}
            {errorCamera &&
                <div className="overlay_box">
                    <div className="alert_box">
                        {
                            (!errorPermiso) ?
                                <p className="animate__animated animate__slideInDown">Hemos detectado que la <b>cámara de tu dispositivo</b> está en uso, por favor asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                                :
                                (isIOS) ?
                                    <p className="animate__animated animate__slideInDown">Por favor <b>acepta el permiso</b> de uso de la camará y da click en volver a intentar para continuar.</p>
                                    :
                                    <p className="animate__animated animate__slideInDown">Revisa las instrucciones para restablecer los <b>permisos de tu navegador</b> y da click en volver a intentar para continuar.
                                        <img src="images/permisos.gif" className="imgPermisos" alt="Reestablecer permisos" />
                                    </p>
                        }
                        <button type="button" onClick={(e) => {
                            if (errorPermiso) {
                                window.location.reload();
                            } else {
                                setErrorCamera(false)
                                permisoCamara();
                            }
                        }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                    </div>
                </div>
            }
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {(cancelProcess) &&
                <div className="modal fade show" style={{ display: "block", color: "#212529", textAlign: "left", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontWeight: "400" }} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancelar proceso</h5>
                                <button onClick={() => setCancelProcess(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>En este momento se cancelará el proceso, ningún dato será guardado y perderás el avance, esta acción no podrá deshacerse <br /> ¿Deseas cancelar?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => { setCancelProcess(false); sendEventClick('Captura Video Token', 'NO', {}); }} className="btn btn-secondary" data-dismiss="modal">NO</button>
                                <button type="button" onClick={cancelarFLujo} className="btn btn-raised btn-primary main_bg_color">SÍ</button>
                            </div>
                        </div>
                    </div>
                </div>}
            {showCancelScreen && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={estado} />}
        </>
    );
}

export default withOrientationChange(Index);

import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { sendEventClick, evento, status, statusData, statusError, statusReintento, enviarImagenVideotoken,
    mapearRespuesta, 
    setLocalStorageResultFace,
    mapearError,
    mapearMensajeError,
    convertBase64ToBlob,
    flowIncompleted,
    generateZip,
    validarOtorganteCDC,
    cancelarINE,
    cancelarRENAPO} from "../services/data";
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import { isIOS, isMobile, withOrientationChange } from 'react-device-detect';
import Loader from "./loader";
import ErrorMessageAlert from "./alerts_modals/error_message_alert";
import LimitIntentsAlert from "./alerts_modals/limit_intents";
// import { cancelarRequest, cancelarRequestIne, cancelarRequestRenapo, getDocuments } from "../../services/api";
import LivenessInteractivo from "npm-liveness_interactivo-main";
import ErrorPermisos from '../components/error_permisos/index'


function LivenessIntalablePdf() {

    console.log("Liveness Instalable V 0.2.8");

    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [apiKey, setApiKey] = useState('');
    const [reintentos, setReintentos] = useState(0);
    const [intentos, setIntentos] = useState(0);
    const [bloqueo, setBloqueo] = useState(false);
    const [showAlertRetry, setShowAlertRetry] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [response, setResponse] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [uuidTransaccion, setUuidTransaccion] = useState('');
    const [pathname, setPathname] = useState('')
    const [scorePorcentage, setScorePorcentage] = useState(false)
    const [showIframe, setShowIframe] = useState(true)
    const serviceCall = 'videotoken-pdf'
    const type = 'selfie';
    var frame;
    const [errorPermisos, setErrorPermisos] = useState(false)
    const [permisosDenegados, setPermisosDenegados] = useState(false)

    // Constantes para los Props del componente instalable

    const urlEnvironment = process.env.REACT_APP_URL 
    const environment = (process.env.REACT_APP_SERVICE)
    const ambiente = (process.env.REACT_APP_SERVICE).replace('_', '')
    const host ='api.'+ ambiente + 'dicio.net'
    const bucket = 'dco-bucket-ciencia'
    const imageFolderReal = ambiente
    const imageFolderSpoof = ambiente
    const refLiveness = useRef(null)

    useEffect(() => {

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
            //console.log(apiKey);
        }

        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx !== null)
            setUuidTransaccion(uuidTrx);

        setPathname(window.location.pathname)

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }

        let block = localStorage.getItem('bloqueo')
        if (block == "true") {
            setBloqueo(true);
            setTimeout(() => {
                terminariFramePostMessage()
            }, 5000);
        }

        status("captura_video_token_page", "Captura Video Token")

        setTimeout(() => {
            usoDeCamara()
        }, 5000);

    }, [])

    const usoDeCamara = async () => {
        console.log("Entro a uso de camara");
        try {
            const localStream = await window.navigator.mediaDevices.getUserMedia({
                video: true
            })

            if (localStream) {
                console.log("Camara activa", localStream);
            }
            else {
                console.log("Error en cámara");
            }

        } catch (error) {
            console.log("Algún error",error)
        }

    }

    useEffect(() => {
        configStyle(dataOtorgante)

        let intents = (Number(obtenerValorConfig(dataOtorgante, 'INTENTOS_VIDEO_TOKEN', 5)))
        intents && setIntentos(intents)

    }, [dataOtorgante])

    useEffect(() => { 
        if (reintentos > 0 ) {
            let informacion = 'Reintento ' + reintentos
            if (reintentos > intentos ) {
                localStorage.setItem('bloqueo', "true");
                setShowAlertRetry(false);
                evento("Liveness Interactivo máximo de reintentos", "Success", informacion, true);
            } else {
                refLiveness.current.reiniciarProceso()
                evento("Liveness Interactivo reintento", "Success", informacion, true);
            }
        }
    }, [reintentos, errorStatus])

    /* Funciones del Componente */
    const getResponse = response => {
        let informacion = response
        if (response.mensaje === "Persona real") { //REAL
            // console.log("Mensaje persona real", response);
            evento("Liveness Interactivo", "Success", informacion, true);
            setScorePorcentage(true);
            let clasificacion = response.clase;
            let porcentaje = response.score;
            localStorage.setItem("scoreObject", JSON.stringify({
                claseLiveness: clasificacion.trim(), 
                porcentajeLiveness: parseFloat(porcentaje.trim())
            }))
            setScorePorcentage(true);
        } else if (response.mensaje === "Cancelar") {
            // console.log("Mensaje botón. CANCELAR");
            sendEventClick('Cancelar Liveness', 'CANCELAR ')
            setTimeout(() => {
                // cancelProcess()
                // history.push('/preparacion_liveness_pdf');
                history.push(`/pdf_token?uuid=${localStorage.getItem("uuidUser") &&localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("uuidOtorgante")}&uuidTrx=${localStorage.getItem("uuidTrx") && localStorage.getItem("uuidTrx")}&fecha=${localStorage.getItem("fechavigencia")}`)
            }, 300);
        } else{
            if (response.mensaje === "No se detectó rostro válido") {
                // console.log("Mensaje spoof");
                setMensajeError("Lo sentimos detectamos inconsistencias en tu captura, por favor intenta nuevamente");
            } else if (response.mensaje === "Se excedio el tiempo") {
                // console.log("Mensaje tiempo excedido");
                setMensajeError("No hemos logrado validar tu captura, por favor sigue las instrucciones e intenta nuevamente");
            } else if (response.mensaje === "Servicio de spoofing no disponible") {
                // console.log("Mensaje servicio de spoofing no disponible");
                setMensajeError("Revisa tu conexión a internet e intenta nuevamente");
            } else if (response.mensaje === "Error en el servicio de spoofing") {
                // console.log("Mensaje error servicio spoofing");
                setMensajeError("Revisa tu conexión a internet e intenta nuevamente");
            } else if (response.mensaje === "Error servicio guardar imagen") {
                // console.log("Mensaje error servicio guardar imagen");
                setMensajeError("Revisa tu conexión a internet e intenta nuevamente");
            } else if (response.mensaje === "Falló la carga del modelo") {
                // console.log("Mensaje error al cargar modelos");
                setMensajeError("Revisa tu conexión a internet e intenta nuevamente");
            }
            // console.log("ERROR",response.mensaje);
            evento("Liveness Interactivo", "Success", informacion, true);
            
            checkIntentos();
            setErrorStatus(response.data);
        }

    }

    const getImage = payload => {
        const image = payload.imagen;
        enviarImagen(image)
    }

    const getErrorPermissions = error => {
        // console.log("getErrorPermissions", error);
        let informacion = error.status
        if (error.status === "PERMISOS DENEGADOS") {
            // console.log("Error", error.status);
            setPermisosDenegados(true)
            evento("Liveness Interactivo", "Success", informacion, true);

        }
        setErrorPermisos(true)
    }

    const getPermissions = () => {
        setPermisosDenegados(false)
        window.location.reload()
    }

    const sendData = (match) => {
        //Cuando es exitoso, revisar la bandera  de speach
        statusData("captura_video_token", { coincidencia: match })
    }

    const enviarImagen = async (image) => {
        if (image) {
            let imageBlob = await convertBase64ToBlob(image)
            try {
                const response = await enviarImagenVideotoken(imageBlob, isIOS, serviceCall)
                const { status, data } = response
                if (status === 200) {
                    evento('Captura videtoken', 'Succes', mapearRespuesta(status, data), true)
                    setLocalStorageResultFace(data.payload)
                    localStorage.setItem('enlace', data.payload.documentLink)
                    setLoading(false);
                    setTimeout(() => {
                        setReintentos(0);
                        localStorage.setItem("bloqueo", false);
                        history.push('/pdf_token_result')
                    }, 300)
                }
            } catch (error) {
                let errorMapeado = mapearError(error)
                evento('Captura videtoken', errorMapeado.tipoError, errorMapeado.objetoError, false)
                if (errorMapeado.objetoError.tipo === 'RESPONSE') {
                    setResponse(errorMapeado)
                    let mensaje = ''
                    if (error.response.data) {
                        mensaje = error.response.data.message_client
                    }
                    let errorMessage = mapearMensajeError(mensaje)
                    if (error.response.status === 500 || error.response.status === 400) {
                        if (mensaje === 'No se encontro rostro' || mensaje === 'Se detecto cubrebocas' || mensaje === 'Condiciones de luz no adecuadas' || mensaje === 'Imagen borrosa' || mensaje === 'Se detectaron multiples caras' || mensaje === 'Falta de luz' || mensaje === 'No se pudo realizar la comparacion de rostro' || mensaje === 'No logramos detectar un rostro') {
                            setMensajeError(errorMessage)
                            setErrorStatus(errorMessage)
                            // setError(true)
                            checkIntentos()
                            setLoading(false)
                        } else {
                            // setReintentos(reintentos + 1)
                            setMensajeError(errorMessage)
                            setErrorStatus(errorMessage)
                            // setError(true)
                            checkIntentos()
                            setLoading(false)
                        }
                    } else if (error.response.status === 404) {
                        setMensajeError(errorMessage)
                        setErrorStatus(errorMessage)
                        // setError(true)
                        checkIntentos()
                        setLoading(false)
                        // setReintentos(reintentos + 1)
                    } else {
                        setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        // setError(true)
                        checkIntentos()
                        setLoading(false)
                    }
                } else {
                    if (error.toString().includes('Network')) {
                        setMensajeError('Revisa tu conexión a internet e intenta nuevamente')
                        setErrorStatus('Revisa tu conexión a internet e intenta nuevamente')
                        // setError(true)
                        checkIntentos()
                        setLoading(false)
                    } else if (error.toString().includes('cancel')) {
                        setMensajeError('Se canceló el flujo')
                        setErrorStatus('Se canceló el flujo')
                        setLoading(false)
                    } else {
                        setMensajeError('El servicio no se encuentra disponible, lo solucionaremos en breve')
                        setErrorStatus('Error en la captura')
                        // setError(true)
                        checkIntentos()
                        setLoading(false)
                    }
                }
            }
        }
        setLoading(false);
    }

    //FUNCTION ON RETRY FOR ERROR API REQUEST
    const functionOnRetryApiError = () => {
        // retryiFramePostMessage();
        setError(false)
        setMensajeError('')
        setReintentos(reintentos + 1);
        sendEventClick('Reintento Liveness api request', 'CLICK ')
    }

    //FUNCTION ON RETRY FOR ERROR iFRAME RETRY
    const functionOnRetryIframeError = () => {
        setShowAlertRetry(false)
        setMensajeError('')
        setReintentos(reintentos + 1);
        sendEventClick('Reintento Liveness', 'CLICK ')
    }

    const functionOnOut = () => {
        // canceliFramePostMessage()
        sendEventClick('Captura Video Token', 'CANCELAR')
        // cancelarFLujo()
        history.push(`/pdf_token?uuid=${localStorage.getItem("uuidUser") &&localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("uuidOtorgante")}&uuidTrx=${localStorage.getItem("uuidTrx") && localStorage.getItem("uuidTrx")}&fecha=${localStorage.getItem("fechavigencia")}`)
    }

    const functionOnCancel = () => {
        sendEventClick('Liveness Interactivo', 'CANCELAR')
        setTimeout(() => {
            cancelProcess()
            history.push(`/pdf_token?uuid=${localStorage.getItem("uuidUser") &&localStorage.getItem("uuidUser")}&idOtorgante=${localStorage.getItem("uuidOtorgante")}&uuidTrx=${localStorage.getItem("uuidTrx") && localStorage.getItem("uuidTrx")}&fecha=${localStorage.getItem("fechavigencia")}`)
        }, 300);
    }
    
    const terminariFramePostMessage = () => {
        history.push('/' + apiKey)    
    }

    const cancelarFLujo = async () => {
        // setLoading(true)
        // cancelarINE();
        // cancelarRENAPO();
        // cancelarRequest();
        // cancelarRequestRenapo()
        // cancelarRequestIne()
        sendEventClick('Captura Video Token', 'SÍ', { status: 'CANCELADO' })
        localStorage.setItem('flag', 'cancelado')
        // await generateZip('TRUNCOS', 'trunco', isIOS)
        statusError("captura_video_token", 'Cancelado', "cancelado")
        if (window.opener) {
            setTimeout(() => {
                window.close()
            }, 300);
        } else {
            // if (validarRedireccionamiento(dataOtorgante)) {
                setTimeout(() => {
                    history.push('/' + apiKey)
                    setLoading(false)
                }, 5000);
            // }
        }
    }

    const stopVideo = () => {
        // Esta función solo detiene el streaming del tag de video, no detiene el proceso
        refLiveness.current.detenerVideo()
    }

    const cancelProcess = () => {
        // Esta función pausa el streaming, cancela los loops internos, reinicia los valores de variables e inhabilita el botón "CANCELAR"
        refLiveness.current.cancelarProceso()
    }

    const retryVideo = () => {
        // Esta función asigna el streaming al tag de video, esta función es una promesa (Promise) y se debe manejar como tal
        refLiveness.current.reiniciarVideo()
    }

    const checkIntentos = () => {
        if (reintentos >= intentos && reintentos != 0) {
            localStorage.setItem('bloqueo', "true");
            setBloqueo(true)
            sendEventClick('Liveness Interactivo', 'BLOQUEO')
        } else {
            // console.log("else");
            setShowAlertRetry(true)
        }
    }

    return (
        <div id="webview" className="">
            <div className="" >
                <LivenessInteractivo 
                    uuidTransaccion = {uuidTransaccion}
                    uuidCliente = {localStorage.getItem('uuidUser')}
                    uuidOtorgante={localStorage.getItem('uuidOtorgante')}
                    apikey = {localStorage.getItem('apikeyOtorgante')}
                    environment = {environment}
                    urlEnvironment = {urlEnvironment}
                    bucket = {bucket}
                    host = {host}
                    getErrorPermissions = {getErrorPermissions}
                    getResponse = {getResponse}
                    getImage = {getImage}
                    videoFolder = 'test-pruebasFront'
                    imageFolderReal = {imageFolderReal}
                    imageFolderSpoof = {imageFolderSpoof}
                    ref = {refLiveness}
                />
            </div>

            {(showAlertRetry && (reintentos <= intentos)) &&
                <ErrorMessageAlert 
                    errorMessage={mensajeError}
                    functionOnRetry={functionOnRetryIframeError}
                    functionOnCancel={functionOnCancel}
                />        
            }

            {(reintentos > intentos) && (
                <LimitIntentsAlert 
                    functionOnOut={functionOnOut} 
                    uuidTransaccion={uuidTransaccion}
                    dataOtorgante={dataOtorgante}
                    pathname={pathname}
                />
            )}

            {bloqueo && (
                <LimitIntentsAlert 
                    functionOnOut={functionOnOut} 
                    uuidTransaccion={uuidTransaccion}
                    dataOtorgante={dataOtorgante}
                    pathname={pathname}
                />
            )}

            { error && mensajeError && reintentos < intentos && (
                <ErrorMessageAlert 
                    errorMessage={mensajeError}
                    functionOnRetry={functionOnRetryApiError}
                    functionOnCancel={functionOnCancel}
                />
            )}

            { loading && (
                <Loader />
            )}

            {errorPermisos && 
                <ErrorPermisos 
                    permisosDenegados={permisosDenegados} 
                    setErrorPermisos={setErrorPermisos} 
                    permisoCamara={getPermissions} 
                />
            }
        </div>
        )
}

export default LivenessIntalablePdf;